import styled, { keyframes } from 'styled-components/macro'

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

export const StyledSVG = styled.svg<{ size: string; stroke?: string }>`
    animation: 2s ${rotate} linear infinite;
    height: ${({ size }) => size};
    width: ${({ size }) => size};

    path {
        stroke: ${({ stroke, theme }) => stroke ?? theme.winterMainButton};
    }
`

const loadingAnimation = keyframes`
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
`

export const LoadingRows = styled.div`
    display: grid;
    min-width: 75%;
    max-width: 100%;
    grid-column-gap: 0.5em;
    grid-row-gap: 0.8em;
    grid-template-columns: repeat(3, 1fr);

    & > div {
        animation: ${loadingAnimation} 1.5s infinite;
        animation-fill-mode: both;
        background: linear-gradient(to left,
        ${({ theme }) => theme.bg1} 25%,
        ${({ theme }) => theme.bg2} 50%,
        ${({ theme }) => theme.bg1} 75%);
        background-size: 400%;
        border-radius: 12px;
        height: 2.4em;
        will-change: background-position;
    }

    & > div:nth-child(4n + 1) {
        grid-column: 1 / 3;
    }

    & > div:nth-child(4n) {
        grid-column: 3 / 4;
        margin-bottom: 2em;
    }
`
