import styled from 'styled-components/macro'

export const StyledImgLogo = styled.img<{ size: string }>`
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.075);
    border-radius: 24px;
`

export const StyledLogo = styled.div<{ size: string }>`
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    border-radius: ${({ size }) => size};
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.075);
    background-color: ${({ theme }) => theme.white};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: Montserrat, serif;
    font-weight: 600;
`
