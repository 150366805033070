import JSBI from 'jsbi'
import { ONE, ZERO } from '../internalConstants'

export abstract class FullMath {
    /**
     * Cannot be constructed.
     */

    public static mulDivRoundingUp(a: JSBI, b: JSBI, denominator: JSBI): JSBI {
        const product = JSBI.multiply(a, b)
        let result = JSBI.divide(product, denominator)
        if (JSBI.notEqual(JSBI.remainder(product, denominator), ZERO)) result = JSBI.add(result, ONE)
        return result
    }
}
